<template>
    <footer id="footer" class="border-0 bg-white">
        <div class="container">
            <!-- Footer Widgets
            ============================================= -->
            <div class="footer-widgets-wrap  clearfix">

                <div class="row col-mb-50">
                    <div class="col-sm-12 col-md-8">

                        <div class="widget clearfix">

                            <img src="../assets/images/logo-tip.png" alt="Dartcomit Logo" class="alignleft" style="max-width:200px; margin-top: 8px; padding-right: 18px; border-right: 1px solid #DDD;">

                            <p>Thanks to the innovations and the high technologies, we are able to increase the level of your business.</p>

                            <div class="line line-sm"></div>


                        </div>

                    </div>

                    <div class="col-md-4">
                        <div class="widget clearfix">

                            <div class="row clearfix">
                                <div class="col-12">
                                    <div class="feature-box fbox-plain fbox-sm align-items-center">
                                        <div class="fbox-icon">
                                            <i class="icon-mail text-dark"></i>
                                        </div>
                                        <div class="fbox-content">
                                            <span class="text-muted">Email Us:</span><br>
                                            <h3 class="nott ls0 font-weight-semibold">craftytechceo@yahoo.com</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div><!-- .footer-widgets-wrap end -->
        </div>

        <!-- Copyrights
        ============================================= -->
        <div id="copyrights" style="">
            <div class="container clearfix">

                <div class="row justify-content-between col-mb-30">
                    <div class="col-12 col-lg-auto text-center text-lg-left">
                        Copyrights &copy; 2020 All Rights Reserved by Crafty Tech, SRL.<br>
                        <div class="copyright-links"><a href="#">Terms of Use</a> / <a href="#">Privacy Policy</a></div>
                    </div>

                    <div class="col-12 col-lg-auto text-center text-lg-right">
                        <div class="copyrights-menu copyright-links clearfix">
                            <a href="/">Home</a>/<a href="/brief">Fill a brief</a>/<a href="/apps">Our Apps</a>/<a href="/solutions">Solutions</a>/<a href="/contact">Contact</a>
                        </div>
                    </div>
                </div>

            </div>
        </div><!-- #copyrights end -->
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
    #footer .footer-widgets-wrap {
        position: relative;
        padding: 0 0;
    }
    #copyrights {
        background: url('../assets/demos/seo/images/hero/footer.svg') no-repeat top center;
        background-size: cover;
        padding-top: 70px;
    }
    img {
        image-orientation: none;
    }
</style>